// eslint-disable-next-line max-classes-per-file
import localforage from 'localforage';
import memoryStorageDriver from 'localforage-memoryStorageDriver';
import RestClientSdk, {
  TokenStorage,
  Token,
  HasExpiresAt,
  AuthorizationCodeFlowTokenGenerator,
  TokenStorageInterface,
} from 'rest-client-sdk';
import AccountSdk from '@mapado/account-js-sdk';
import CrmSdk from '@mapado/crm-js-sdk';
import MapadoboxSocketClient from '@mapado/mapadobox-socket-client';
import TicketingSdk from 'mapado-ticketing-js-sdk';

import { DeskSdkType } from '../api/sdk/DeskSdk';
import EntitySerializer from '../api/sdk/entityFactory/EntitySerializer';
import mapping, { TSMetadata } from '../api/sdk/mapping';
import { MAPADO_API_CLIENT_ID, SCOPES } from './constants';
import DESK_DEMO_CONFIG from './deskSdk/demo';
import DESK_PREPROD_CONFIG from './deskSdk/preprod';
import DESK_PROD_CONFIG from './deskSdk/prod';
import DEMO_CONFIG, {
  ACCOUNTS_DOMAIN as DEMO_ACCOUNTS_DOMAIN,
  MAPADO_API_CLIENT_ID as DEMO_MAPADO_API_CLIENT_ID,
} from './sdk/demo';
import PREPROD_CONFIG, {
  ACCOUNTS_DOMAIN as PREPROD_ACCOUNTS_DOMAIN,
} from './sdk/preprod';
import PROD_CONFIG, { ACCOUNTS_DOMAIN } from './sdk/prod';
import type { DeskConfig, DeskSdkConfig } from './types';

const REDIRECT_URI = `${window.location.protocol}//${window.location.host}/oauth/callback`;

export const IS_PREPROD = [
  'desk.preprod.mapado.com',
  'preprod.mapado.com',
  'preprod2.mapado.com',
].includes(window.location.hostname);

let sdkConfiguration: DeskConfig = PROD_CONFIG;
let deskSdkConfiguration: DeskSdkConfig = DESK_PROD_CONFIG;
let accountsDomain = ACCOUNTS_DOMAIN;

let apiClientId = MAPADO_API_CLIENT_ID;

if (process.env.NODE_ENV !== 'production') {
  if (process.env.NODE_ENV === 'demo') {
    sdkConfiguration = DEMO_CONFIG;
    deskSdkConfiguration = DESK_DEMO_CONFIG;

    apiClientId = DEMO_MAPADO_API_CLIENT_ID;
    accountsDomain = DEMO_ACCOUNTS_DOMAIN;
  } else {
    sdkConfiguration = PREPROD_CONFIG;
    deskSdkConfiguration = DESK_PREPROD_CONFIG;
    accountsDomain = PREPROD_ACCOUNTS_DOMAIN;
  }
}

if (IS_PREPROD) {
  sdkConfiguration = PREPROD_CONFIG;
  deskSdkConfiguration = DESK_PREPROD_CONFIG;
  accountsDomain = PREPROD_ACCOUNTS_DOMAIN;
}

class DecoratedTokenStorage implements TokenStorageInterface<Token> {
  #decoratedTokenStorage: TokenStorageInterface<Token>;

  constructor(decoratedTokenStorage: TokenStorageInterface<Token>) {
    this.#decoratedTokenStorage = decoratedTokenStorage;
  }

  hasAccessToken(): Promise<boolean> {
    return this.#decoratedTokenStorage.hasAccessToken();
  }

  getAccessToken(): Promise<null | string> {
    return this.#decoratedTokenStorage.getAccessToken();
  }

  getAccessTokenObject(): Promise<null | (Token & HasExpiresAt)> {
    return this.#decoratedTokenStorage.getAccessTokenObject();
  }

  logout(): Promise<void> {
    return this.#decoratedTokenStorage.logout();
  }

  async generateToken(parameters: unknown): Promise<Token & HasExpiresAt> {
    const token = await this.#decoratedTokenStorage.generateToken(parameters);

    this.updateSocketClientAccessToken(token);

    return token;
  }

  async refreshToken(): Promise<Token & HasExpiresAt> {
    const token = await this.#decoratedTokenStorage.refreshToken();

    this.updateSocketClientAccessToken(token);

    return token;
  }

  getCurrentTokenExpiresIn(): Promise<number | null> {
    return this.#decoratedTokenStorage.getCurrentTokenExpiresIn();
  }

  private updateSocketClientAccessToken(token: Token): void {
    if (
      token.access_token &&
      window.mapadoboxSocketClient &&
      window.mapadoboxSocketClient.updateAccessToken
    ) {
      window.mapadoboxSocketClient.updateAccessToken(token.access_token);
    }
  }
}

const tokenGenerator = new AuthorizationCodeFlowTokenGenerator({
  clientId: apiClientId,
  clientSecret: 'proxified',
  path: sdkConfiguration.oauth.path,
  scheme: sdkConfiguration.oauth.scheme,
  redirectUri: REDIRECT_URI,
  scope: SCOPES,
});

const generateClientId = (): string =>
  `${Date.now()}-${Math.floor(Math.random() * 100000000)
    .toString(36)
    .padStart(6, '0')}`;

export function generateSocketClientIfNeeded(ticketingSdk: TicketingSdk): void {
  const { tokenStorage } = ticketingSdk;

  tokenStorage.hasAccessToken().then((hasAccessToken: boolean) => {
    // if no token is stored yet, we will be redirected to the login later
    if (hasAccessToken) {
      window.mapadoboxSocketClient = new MapadoboxSocketClient(
        sdkConfiguration.socket,
        'desk',
        generateClientId(),
        // simulate token storage as used in socket client
        tokenStorage
      );
    }
  });
}

export default function configureSdk(): Promise<
  (TicketingSdk | DeskSdkType | AccountSdk)[]
> {
  function createTicketingSdk(): TicketingSdk {
    const tokenStorage = new DecoratedTokenStorage(
      new TokenStorage(tokenGenerator, localforage)
    );
    const ticketingSdk = new TicketingSdk(tokenStorage, {
      ...sdkConfiguration.ticketing,
      onRefreshTokenFailure: () => {
        redirectToLoginPage();
      },
    });

    window.crmSdk = new CrmSdk(tokenStorage, sdkConfiguration.crm);

    // eslint-disable-next-line no-console
    console.log(`--- init Mapado Ticketing Desk v${process.env.VERSION} ---`);

    generateSocketClientIfNeeded(ticketingSdk);

    return ticketingSdk;
  }

  function createDeskSdk(): DeskSdkType {
    const tokenStorage = new DecoratedTokenStorage(
      new TokenStorage(tokenGenerator, localforage)
    );

    // @ts-expect-error we do have a DeskSdkType here
    const deskSdk: DeskSdkType = new RestClientSdk<TSMetadata>(
      tokenStorage,
      deskSdkConfiguration,
      mapping,
      /** @ts-expect-error -- EntitySerializer is in fact a valid SerializerInterface. @see EntitySerializer::normalizeItem */
      new EntitySerializer()
    );

    return deskSdk;
  }

  function createAccountSdk(): AccountSdk {
    const tokenStorage = new DecoratedTokenStorage(
      new TokenStorage(tokenGenerator, localforage)
    );

    const accountSdk = new AccountSdk(tokenStorage, sdkConfiguration.account);

    return accountSdk;
  }

  // Test if we can set stuff in the local storage
  // if we can't we use a memory driver
  // (mostly for private navigation issue, such as safari, safari mobile, maybe ff)
  return localforage
    .setItem('foo', 'bar')
    .then(() => {
      localforage.removeItem('foo');
      // eslint-disable-next-line no-console
      console.log('[DEBUG] Use default localforage driver');

      return [createTicketingSdk(), createDeskSdk(), createAccountSdk()];
    })
    .catch(() => {
      // eslint-disable-next-line no-console
      console.log('[DEBUG] Switch to memoryStorageDriver driver');

      return (
        localforage
          .defineDriver(memoryStorageDriver)
          /* eslint-disable no-underscore-dangle */
          .then(() => localforage.setDriver(memoryStorageDriver._driver))
          .then(() => {
            return [createTicketingSdk(), createDeskSdk(), createAccountSdk()];
          })
      );
    });
}

type GetImpersonationUriParameters = {
  // eslint-disable-next-line camelcase
  _switch_user: string;
  // eslint-disable-next-line camelcase
  _switch_redirect_to: string;
};

export function getImpersonationUri(
  parameters: GetImpersonationUriParameters
): string {
  const url: URL = new URL(`https://${accountsDomain}/impersonate`);

  url.search = new URLSearchParams({ ...parameters, theme: 'pro' }).toString();

  return url.toString();
}

export function getAccountsDomain(): string {
  return accountsDomain;
}

export async function redirectToLoginPage(): Promise<void> {
  const state = Math.random().toString(16).substr(2, 8);

  const searchParams = new URLSearchParams();

  searchParams.set('redirect_uri', REDIRECT_URI);
  searchParams.set('theme', 'pro');
  searchParams.set('response_type', 'code');
  searchParams.set('client_id', apiClientId);
  searchParams.set('scope', SCOPES);
  searchParams.set('state', state);

  await localforage.setItem('redirectUrl', window.location.href);
  await localforage.setItem('state', state);

  window.location.href = `https://${accountsDomain}/oauth/v2/auth?${searchParams.toString()}`;
}

export function getLogoutUrl(): string {
  return `https://${accountsDomain}/logout?theme=pro&redirectUrl=${window.location.origin}`;
}

export function getSdkConfiguration(): DeskConfig {
  return { ...sdkConfiguration };
}
