import { SCOPES } from '../constants';
import { DeskConfig } from '../types';

export const ACCOUNTS_DOMAIN = 'accounts.demo.mapado.com';

const Config: DeskConfig = {
  oauth: {
    path: 'desk.demo.mapado.com/api/oauth/proxy',
    scheme: 'https',
    scope: SCOPES,
  },

  ticketing: {
    path: 'ticketing.demo.mapado.net',
    scheme: 'https',
    unitOfWorkEnabled: true,
  },

  crm: {
    path: 'crm.demo.mapado.net',
    scheme: 'https',
  },

  account: {
    path: 'account.preprod.mapado.com',
    scheme: 'https',
    unitOfWorkEnabled: true,
  },

  socket: {
    scheme: 'https',
    host: 'socket.demo.mapado.net',
    port: '443',
  },
};

export default Config;

export const MAPADO_API_CLIENT_ID = '2_abcdefghi';
