import { DeskSdkConfig } from '../types';

let deskDomain = 'desk.preprod.mapado.com';

if (process.env.VADER_USER) {
  deskDomain = `desk.${process.env.VADER_USER}.dev.mapado.com`;
}

const Config: DeskSdkConfig = {
  path: deskDomain,
  scheme: 'https',
  unitOfWorkEnabled: true,
};

export default Config;
