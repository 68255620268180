import { AbstractClient } from 'rest-client-sdk';
// eslint-disable-next-line import/no-cycle
import { TSMetadata } from '../mapping';

class UserClient extends AbstractClient<TSMetadata['user']> {
  getPathBase(): string {
    return '/api/users';
  }
}

export default UserClient;
