/* eslint-disable consistent-return */
import clone from 'clone';
import { fromJS, Map, List, MapOf, Record as ImmutableRecord } from 'immutable';
import { MomentInstance } from 'mapado-ticketing-js-sdk';
import type { PartialEntity } from '../entity';
import Collection, {
  ViewType,
  CollectionType,
  CollectionInputType,
} from '../entity/Collection';
import ContractUser, { ContractUserType } from '../entity/ContractUser';
import DeskNotification, {
  DeskNotificationType,
} from '../entity/DeskNotification';
import SupportResource, {
  SupportResourceType,
} from '../entity/SupportResource';
import User, { UserType } from '../entity/User';
import UserDeskNotification, {
  UserDeskNotificationType,
} from '../entity/UserDeskNotification';
import type { AllowedFactoryTypes, Entity, EntityTypes } from './types';

function mapListToEntityList(
  list: Array<AllowedFactoryTypes>
): List<EntityTypes> {
  return List(list.map((value) => createEntity(value) as EntityTypes));
}

function mapEntityRelationShips<E extends Entity>(
  entity: E,
  baseJson: AllowedFactoryTypes
): E {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /** @ts-expect-error -- really hard for now */
  return entity.withMutations((mutableEntity: E) => {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const prop in baseJson) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /** @ts-expect-error */
      const val = baseJson[prop];

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /** @ts-expect-error -- prop does exist, no need to have a default value */
      if (!val || !entity.get(prop)) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (Array.isArray(val)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /** @ts-expect-error */
        mutableEntity.set(prop, mapListToEntityList(val));
      } else if (List.isList(val)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /** @ts-expect-error */
        mutableEntity.set(prop, val);
      } else if (val instanceof ImmutableRecord) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /** @ts-expect-error */
        mutableEntity.set(prop, val);
      } else if (typeof val === 'object' && val !== null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /** @ts-expect-error */
        mutableEntity.set(prop, createEntity(val as AllowedFactoryTypes));
      }
    }
  });
}

class EntityFactory {
  public static createEntity(val: null): undefined;

  public static createEntity(
    val: PartialEntity<DeskNotificationType>
  ): DeskNotification;

  public static createEntity(
    val: PartialEntity<UserDeskNotificationType>
  ): UserDeskNotification;

  public static createEntity(
    val: PartialEntity<SupportResourceType>
  ): SupportResource;

  public static createEntity(
    val: PartialEntity<ContractUserType>
  ): ContractUser;

  public static createEntity(val: PartialEntity<UserType>): User;

  public static createEntity<O extends Entity>(
    val: CollectionType<AllowedFactoryTypes>
  ): Collection<O>;

  public static createEntity(val: AllowedFactoryTypes): unknown;

  public static createEntity(
    val: null | AllowedFactoryTypes | CollectionType<AllowedFactoryTypes>
  ) {
    if (!val) {
      return;
    }

    /* eslint-disable @typescript-eslint/ban-ts-comment */
    switch (val['@type']) {
      case 'hydra:Collection': {
        const input = clone(val) as CollectionInputType<Entity>;
        const parameters: Partial<CollectionType<Entity>> = {
          ...input,
          'hydra:member': input['hydra:member']
            ? List(
                input['hydra:member'].map(
                  (member: AllowedFactoryTypes): Entity => {
                    return createEntity(
                      member as AllowedFactoryTypes
                    ) as Entity;
                  }
                )
              )
            : List<Entity>(),
          'hydra:view': input['hydra:view']
            ? (fromJS(input['hydra:view']) as MapOf<ViewType>)
            : Map<ViewType>({
                '@type': 'hydra:PartialCollectionView',
              }),
        };

        return new Collection(parameters);
      }

      case 'DeskNotification': {
        return mapEntityRelationShips(
          new DeskNotification(clone(val)),
          clone(val)
        );
      }

      case 'SupportResource': {
        return mapEntityRelationShips(
          new SupportResource(clone(val)),
          clone(val)
        );
      }

      case 'ContractUser': {
        return mapEntityRelationShips(new ContractUser(clone(val)), clone(val));
      }

      case 'User': {
        return mapEntityRelationShips(new User(clone(val)), clone(val));
      }

      case 'UserDeskNotification': {
        const data = clone(val);

        data.discardedAt =
          data.discardedAt && MomentInstance.moment.utc(data.discardedAt);
        data.viewedAt =
          data.viewedAt && MomentInstance.moment.utc(data.viewedAt);

        return mapEntityRelationShips(
          new UserDeskNotification(clone(val)),
          clone(val)
        );
      }

      default:
        return fromJS(clone(val)) as Map<string, unknown>;
    }
    /* eslint-enable @typescript-eslint/ban-ts-comment */
  }
}

// eslint-disable-next-line prefer-destructuring
const createEntity = EntityFactory.createEntity;

export default createEntity;
