import { ClassMetadata, Serializer } from 'rest-client-sdk';
import createEntity from './EntityFactory';
import { AllowedFactoryTypes, Entity, EntityTypes } from './types';

/* eslint-disable @typescript-eslint/no-unused-vars */
export default class EntitySerializer extends Serializer {
  /** @ts-expect-error -- Entity is a valid entity to normalize */
  normalizeItem(
    entity: Entity,
    classMetadata: ClassMetadata
  ): Record<string, unknown> {
    return entity.toJSON();
  }

  encodeItem(
    object: Record<string, unknown>,
    classMetadata: ClassMetadata
  ): string {
    return JSON.stringify(object);
  }

  decodeItem(
    rawData: string,
    classMetadata: ClassMetadata,
    response: Response
  ): Record<string, unknown> {
    return JSON.parse(rawData);
  }

  denormalizeItem(
    object: AllowedFactoryTypes,
    classMetadata: ClassMetadata,
    response: Response
  ): EntityTypes {
    // do stuff with your item input

    if (object) {
      return createEntity(object) as EntityTypes;
    }

    throw new Error('Unable to denormalize item.');
  }

  decodeList(
    rawListData: string,
    classMetadata: ClassMetadata,
    response: Response
  ): Iterable<Record<string, unknown>> {
    return JSON.parse(rawListData);
  }

  denormalizeList(
    objectList: Iterable<Record<string, unknown>>,
    classMetadata: ClassMetadata,
    response: Response
  ): Iterable<Record<string, unknown>> {
    /** @ts-expect-error -- really really hard / fucked up */
    return createEntity(objectList);
  }
}
