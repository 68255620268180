import { SCOPES } from '../constants';
import { DeskConfig } from '../types';

let accountsDomain = 'accounts.preprod.mapado.com';

if (process.env.VADER_USER) {
  accountsDomain = `accounts.${process.env.VADER_USER}.dev.mapado.com`;
}

export const ACCOUNTS_DOMAIN = accountsDomain;

let ticketingDevPath = 'ticketing.preprod.mapado.net';

if (process.env.VADER_USER) {
  ticketingDevPath = `ticketing.${process.env.VADER_USER}.dev.mapado.net`;
}

let deskDomain = 'desk.preprod.mapado.com';

if (process.env.VADER_USER) {
  deskDomain = `desk.${process.env.VADER_USER}.dev.mapado.com`;
}

let crmPath = 'crm.preprod.mapado.net';

if (process.env.VADER_USER) {
  crmPath = `crm.${process.env.VADER_USER}.dev.mapado.net`;
}

let accountPath = 'accounts.preprod.mapado.com';

if (process.env.VADER_USER) {
  accountPath = `accounts.${process.env.VADER_USER}.dev.mapado.com`;
}

const Config: DeskConfig = {
  oauth: {
    path: `${deskDomain}/api/oauth/proxy`,
    scheme: 'https',
    scope: SCOPES,
  },

  ticketing: {
    path: ticketingDevPath,
    scheme: 'https',
    unitOfWorkEnabled: true,
  },

  crm: {
    path: crmPath,
    scheme: 'https',
  },

  account: {
    path: accountPath,
    scheme: 'https',
    unitOfWorkEnabled: true,
  },

  socket: {
    scheme: 'https',
    host: 'socket.preprod.mapado.net',
    port: '3222',
  },
};

export default Config;
