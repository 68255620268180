import { AbstractClient } from 'rest-client-sdk';
// eslint-disable-next-line import/no-cycle
import { TSMetadata } from '../mapping';

class ContractUserClient extends AbstractClient<TSMetadata['contractUser']> {
  getPathBase(): string {
    return '/api/contract_users';
  }
}

export default ContractUserClient;
