export const ROUTE_TICKETING_EVENT_DATE_LIST = '/activity/:slug/events';

export const ROUTE_HOME = '/';
export const ROUTE_OAUTH_CALLBACK = '/oauth/callback';
export const ROUTE_EVENTS = '/events';
export const ROUTE_EVENT_DATE_DATE_LIST = '/event-list/:date';
export const ROUTE_SALES = '/sales';
export const ROUTE_SALES_CASH_REGISTER = '/sales/cash-register';
export const ROUTE_SALES_CASH_REGISTER_SETTINGS =
  '/sales/cash-register-settings';
export const ROUTE_PAYMENT_SUCCESS = '/payment/success';
export const ROUTE_PAYMENT = '/payment';
export const ROUTE_RESERVATIONS = '/reservations';
export const ROUTE_RESERVATIONS_EVENT_DATE = '/reservations/:eventDateId';
export const ROUTE_PAYOUT = '/payout';
export const ROUTE_PREFIX_REPORT = '/report';
export const ROUTE_REPORT_SALES = '/report/sales';
export const ROUTE_REPORT_PROGRAMMING = '/report/programming';
export const ROUTE_REPORT_DAILY = '/report/daily';
export const ROUTE_REPORT_ACCOUNTING = '/report/accounting';
export const ROUTE_REPORT_DATA = '/report/data';
export const ROUTE_PRINT_PARAMETERS = '/printer';
export const ROUTE_OPTION_ALLOCATION = '/carts';
export const ROUTE_DETAIL_OPTION = '/carts/:cartId/option';
export const ROUTE_DETAIL_RESERVATION = '/carts/:cartId/reservation';
export const ROUTE_DETAIL_FORM_DATA = '/carts/:cartId/form-data';
export const ROUTE_CART_PAYMENT = '/carts/:cartId/payment';
export const ROUTE_CARTS_MOVE_SEATS = '/carts/:cartId/move-seats/:eventDateId';
export const ROUTE_CART = '/cart';
export const ROUTE_CART_REDIRECT_TO = '/cart/:cartId/redirectTo';
export const ROUTE_CART_MOVE_SEATS = '/cart/:cartId/move-seats/:eventDateId';
export const ROUTE_CART_NEW_CUSTOMER = '/cart-customer';
export const ROUTE_CART_NEW_CUSTOMER_RESERVATION = '/cart-customer/:cartId';
export const ROUTE_BANK_ACCOUNT_EDIT = '/payout/bank-account/:walletId';
export const ROUTE_CLIENT_DETAIL = '/client/:clientId';
export const ROUTE_CLIENT_DEPOSIT_DEBIT = '/client/:clientId/debit';
export const ROUTE_CLIENT_DEPOSIT_CREDIT = '/client/:clientId/credit';
export const ROUTE_CLIENT = '/client';
export const ROUTE_RESOURCES = '/resources';

/** @deprecated redirected on ROUTE_MASS_ACTION_ALL_TICKETINGS */
export const ROUTE_TICKETING_CREATE = '/ticketing/create';

export const ROUTE_SETTINGS = '/settings';

export const ROUTE_SCAN_TRACKS = '/settings/scan-tracks';
export const ROUTE_SCAN_TRACKS_SCAN_TRACK_NEW =
  '/settings/scan-tracks/scan-track/new';
export const ROUTE_SCAN_TRACKS_SCAN_TRACK_EDIT =
  '/settings/scan-tracks/scan-track/edit/:scanTrackId';
export const ROUTE_SCAN_TRACKS_SCAN_TRACKS =
  '/settings/scan-tracks/scan-tracks';
export const ROUTE_SCAN_TRACKS_CHECKPOINT_NEW =
  '/settings/scan-tracks/checkpoint/new';
export const ROUTE_SCAN_TRACKS_CHECKPOINT_EDIT =
  '/settings/scan-tracks/checkpoint/edit/:checkpointId';
export const ROUTE_SCAN_TRACKS_CHECKPOINTS =
  '/settings/scan-tracks/checkpoints';
export const ROUTE_SETTINGS_MANAGE_PRICES = '/settings/manage-prices';
export const ROUTE_SETTINGS_MANAGE_PRICES_CREATE =
  '/settings/manage-prices/create';
export const ROUTE_SETTINGS_MANAGE_PRICES_EDIT =
  '/settings/manage-prices/edit/:ticketPriceId';
export const ROUTE_SETTINGS_MANAGE_MINISITES = '/settings/manage-minisites';
export const ROUTE_SETTINGS_MANAGE_MINISITES_GRAPHIC =
  '/settings/manage-minisites/:minisiteId/graphic';
export const ROUTE_SETTINGS_MANAGE_MINISITES_HOMEPAGE =
  '/settings/manage-minisites/:minisiteId/homepage';
export const ROUTE_SETTINGS_MANAGE_MINISITES_EVENT =
  '/settings/manage-minisites/:minisiteId/event';
export const ROUTE_SETTINGS_MANAGE_MINISITES_ADVANCED =
  '/settings/manage-minisites/:minisiteId/advanced';
export const ROUTE_SETTINGS_LOGICAL_SEAT_CONFIGS =
  '/settings/logical-seat-configs';
export const ROUTE_SETTINGS_LOGICAL_SEAT_CONFIG_NEW =
  '/settings/logical-seat-configs/new';
export const ROUTE_SETTINGS_LOGICAL_SEAT_CONFIG_ADMIN =
  '/settings/logical-seat-configs/:logicalSeatConfigId';
export const ROUTE_SETTINGS_SIBIL = '/settings/sibil';
export const ROUTE_CRM_CUSTOMER_LIST = '/customers';
export const ROUTE_CRM_CUSTOMER_NEW = '/customers/new';
export const ROUTE_CRM_CUSTOMER_SHOW = '/customers/:customerId';
export const ROUTE_CRM_DUPLICATES = '/customers/duplicates';
export const ROUTE_CRM_DUPLICATES_SIMILARITY =
  '/customers/duplicates/similarity';
export const ROUTE_CRM_DUPLICATES_LOCATION = '/customers/duplicates/location';
export const ROUTE_CRM_CUSTOMER_STICKERS = '/customers/stickers';
export const ROUTE_ORDER_DETAILS = '/orders/:orderId';
export const ROUTE_ORDER_FORM_DATA = '/orders/:orderId/form-data';
export const ROUTE_ORDER_NEW_CUSTOMER = '/orders/:orderId/new-customer';
export const ROUTE_ORDER_NEW_CUSTOMER_TICKETS_COUPONS =
  '/orders/:orderId/new-customer-tickets-coupons';
export const ROUTE_ORDER_MOVE_SEATS =
  '/orders/:orderId/move-seats/:eventDateId';
export const ROUTE_ORDER_TRANSACTIONS = '/orders/:orderId/transactions';
export const ROUTE_ORDER_INVOICES = '/orders/:orderId/invoices';
export const ROUTE_ORDER_DEPOSIT_REFUND = '/orders/:orderId/refund';
export const ROUTE_ORDER_DEPOSIT_DEBIT = '/orders/:orderId/debit';
export const ROUTE_ORDER_DEPOSIT_CREDIT = '/orders/:orderId/credit';
export const ROUTE_TRANSACTION_UPDATE = '/orders/:orderId/transactions/correct';
export const ROUTE_USER_RIGHTS = '/settings/user-rights';
export const ROUTE_USER_RIGHTS_USERS = '/settings/user-rights/users';
export const ROUTE_USER_RIGHTS_ROLES = '/settings/user-rights/roles';
export const ROUTE_USER_RIGHTS_NEW_ROLE = '/settings/user-rights/roles/new';
export const ROUTE_USER_RIGHTS_EDIT_ROLE =
  '/settings/user-rights/roles/edit/:roleId';
export const ROUTE_SETTINGS_OFFER = '/settings/offers';
export const ROUTE_SETTINGS_OFFER_CREATE = '/settings/offers/create';
export const ROUTE_SETTINGS_OFFER_EDIT = '/settings/offers/edit/:id';
export const ROUTE_SETTINGS_PAYMENT_METHOD = '/settings/payment-method';
export const ROUTE_SETTINGS_BOOKING_TRACK = '/settings/booking-track';
export const ROUTE_SETTINGS_BOOKING_TRACK_MODE =
  '/settings/booking-track/:bookingTrackId/mode';
export const ROUTE_SETTINGS_BOOKING_TRACK_FORM =
  '/settings/booking-track/:bookingTrackId/form';
export const ROUTE_SETTINGS_PROVIDER_TICKET_MAPPING =
  '/settings/provider-ticket-mapping';
export const ROUTE_SETTINGS_PROVIDER_TICKETING_MAPPING =
  '/settings/provider-ticket-mapping/mapping/ticketing';
export const ROUTE_SETTINGS_PROVIDER_TICKETPRICE_MAPPING =
  '/settings/provider-ticket-mapping/mapping/ticketprice';
export const ROUTE_SETTINGS_PROVIDER_SEATING_MAPPING =
  '/settings/provider-ticket-mapping/mapping/seating';
export const ROUTE_SETTINGS_PROVIDER_MAPPING_NOTIFICATION =
  '/settings/provider-ticket-mapping/manage/notification';
export const ROUTE_SETTINGS_MARKETING = '/settings/marketing';
export const ROUTE_SETTINGS_MARKETING_EMAIL = '/settings/marketing/email';
export const ROUTE_SETTINGS_TICKETS = '/settings/tickets';
export const ROUTE_SETTINGS_TAGS = '/settings/tags';
export const ROUTE_SETTINGS_PROVIDER_CREDENTIALS =
  '/settings/provider-credentials';
export const ROUTE_SETTINGS_CRM = '/settings/crm';
export const ROUTE_SETTINGS_CRM_FIELDS = '/settings/crm/fields';
export const ROUTE_SETTINGS_CRM_IMPORTS = '/settings/crm/imports';
export const ROUTE_SETTINGS_CRM_UPLOAD = '/settings/crm/imports/upload';
export const ROUTE_SETTINGS_TICKET_IMPORT_LIST = '/settings/ticket-import/list';
export const ROUTE_SETTINGS_TICKET_UPLOAD =
  '/settings/ticket-import/event/:eventDateId';
export const ROUTE_SETTINGS_TICKET_UPLOAD_CUSTOM =
  '/settings/ticket-import/event/:eventDateId/custom';
export const ROUTE_SETTINGS_TICKET_UPLOAD_CUSTOM_FILE =
  '/settings/ticket-import/event/:eventDateId/custom/file';
export const ROUTE_SETTINGS_TICKET_UPLOAD_CUSTOM_PREVIEW =
  '/settings/ticket-import/event/:eventDateId/custom/preview';
export const ROUTE_SETTINGS_TICKET_UPLOAD_CUSTOM_CONTROLS =
  '/settings/ticket-import/event/:eventDateId/custom/controls';
export const ROUTE_SETTINGS_TICKET_UPLOAD_CUSTOM_OPTIONS =
  '/settings/ticket-import/event/:eventDateId/custom/options';
export const ROUTE_SETTINGS_TICKET_UPLOAD_CUSTOM_SCAN_TRACK =
  '/settings/ticket-import/event/:eventDateId/custom/scan-track';

export const ROUTE_ONBOARDING = '/onboarding';
export const ROUTE_ONBOARDING_WALLET_VAD = '/onboarding/wallet/:walletId/vad';

export const ROUTE_ONBOARDING_CONTRACT = '/onboarding/:contractId/contract';
export const ROUTE_ONBOARDING_CONTRACT_ADDENDUMS =
  '/onboarding/:contractId/contract_addendums';
export const ROUTE_ONBOARDING_PLAN = '/onboarding/:contractId/plan';
export const ROUTE_ONBOARDING_FINAL = '/onboarding/:contractId/final';

export const ROUTE_EVENT_DATE_PRICE_LIST = '/events/:eventDateId/prices';
export const ROUTE_EVENT_DATE_SETTINGS = '/events/:eventDateId/settings';
export const ROUTE_EVENT_DATE_SEATING = '/events/:eventDateId/seating';
export const ROUTE_EVENT_DATE_CART_LIST = '/events/:eventDateId/carts';
export const ROUTE_EVENT_DATE_RESERVATION = '/events/:eventDateId/reservations';

export const ROUTE_SWITCH_CONTRACT = '/api/contracts/switch/:contractId';

// Mass action routes
export const ROUTE_MASS_ACTION_ALL_TICKETINGS = '/mass-action/ticketing';
export const ROUTE_MASS_ACTION_TICKETING =
  '/mass-action/ticketing/:ticketingShortId';
export const ROUTE_MASS_ACTION_HISTORY = '/mass-action/history';

export const ROUTE_ACCOUNT = '/account';
export const ROUTE_COMPLIANCE_AUDIT_DOCUMENTS =
  '/accountability/compliance-audit-documents';
export const ROUTE_DESK_SESSIONS = '/accountability/desk-sessions';

export const ROUTE_CRM_API_CUSTOMER_FIELDS_CGET =
  '/api/crm/customer-field-contracts/:type';

export const ROUTE_RECURRING_PAYMENT = '/recurring-payment';
