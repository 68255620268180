import { Record } from 'immutable';
import { Moment } from 'moment';
import { Attribute, ClassMetadata, Relation } from 'rest-client-sdk';
// eslint-disable-next-line import/no-cycle
import UserDeskNotificationClient from '../client/UserDeskNotificationClient';
import DeskNotification from './DeskNotification';
import { PartialEntity } from '.';

export type UserDeskNotificationType = {
  '@id': null | string;
  '@type': 'UserDeskNotification';
  deskNotification: null | DeskNotification;
  discardedAt: null | Moment;
  viewedAt: null | Moment;
};

class UserDeskNotification extends Record<UserDeskNotificationType>({
  '@id': null,
  '@type': 'UserDeskNotification',
  deskNotification: null,
  discardedAt: null,
  viewedAt: null,
}) {
  public static classMetadata: ClassMetadata;

  constructor(
    val: PartialEntity<UserDeskNotificationType> = {
      '@id': null,
      '@type': 'UserDeskNotification',
    }
  ) {
    super(val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/user_desk_notifications/', '') || '';
  }
}

UserDeskNotification.classMetadata = new ClassMetadata(
  'userDeskNotification',
  'user_desk_notifications',
  /** @ts-expect-error -- method signature are incompatible */
  UserDeskNotificationClient
);
UserDeskNotification.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('discardedAt', 'discardedAt', 'datetime'),
  new Attribute('viewedAt', 'viewedAt', 'datetime'),
]);
UserDeskNotification.classMetadata.setRelationList([
  new Relation(Relation.MANY_TO_ONE, 'deskNotification', 'deskNotification'),
]);

export default UserDeskNotification;
