import { getImpersonationUri } from './config/configureSdk';
import { route, ROUTE_SWITCH_CONTRACT } from './utils/routing';

const onLoad = () => {
  import('./initApp').then(({ initApp }) => {
    initApp();
  });
};

if (process.env.NODE_ENV === 'development') {
  document.getElementById('favicon').href = '/assets/favicons/dev/favicon.png';
}

const { href } = window.location;
const isHttps = window.location?.protocol === 'https:';

// accept desk on http ONLY on preprod (CI)
// TODO use a environment variable when environment variables are available
const allowHttp =
  window.location.hostname === 'preprod.mapado.com' ||
  window.location.hostname === 'preprod2.mapado.com';

if (!isHttps && !allowHttp) {
  (function redirectToHttps() {
    // eslint-disable-next-line no-console
    console.warn(
      'http is not supported anymore, redirecting to https version of the desk.'
    );
    window.location.href = window.location.href.replace('http:', 'https:');
  })();
} else {
  (function switchUserIfNeeded() {
    const url = new URL(href);
    const switchUser = url.searchParams.get('_switch_user');

    if (switchUser) {
      url.searchParams.delete('_switch_user');

      const redirectUrl = url.toString();
      const toRedirectUrl = getImpersonationUri({
        _switch_user: switchUser,
        _switch_redirect_to: redirectUrl,
      });

      window.location.href = toRedirectUrl;
    }
  })();

  (function switchContractIfNeeded() {
    const currentUrl = new URL(href);
    const switchContract = currentUrl.searchParams.get('_switch_contract');

    if (switchContract) {
      currentUrl.searchParams.delete('_switch_contract');

      const redirectUrl = currentUrl.toString();

      const newLocation = new URL(href);

      newLocation.pathname = route(ROUTE_SWITCH_CONTRACT, {
        contractId: switchContract,
      });
      newLocation.searchParams.delete('_switch_contract');
      newLocation.searchParams.set('redirectUrl', redirectUrl);

      window.location.href = newLocation.toString();
    }
  })();

  let waitPolyfillTimeout = null;
  const polyfillTimeout = setTimeout(() => {
    if (!window.polyfillLoaded) {
      if (waitPolyfillTimeout) {
        clearTimeout(waitPolyfillTimeout);
      }

      // eslint-disable-next-line no-console
      console.log('polyfill not really loaded, fallback', 'warn');
      window.polyfillLoaded = true;
      onLoad();
    }
  }, 3000);

  const waitPolyfill = () => {
    if (window.polyfillLoaded === true) {
      clearTimeout(polyfillTimeout);
      // eslint-disable-next-line no-console
      console.log('polyfill loaded');
      onLoad();
    } else {
      // eslint-disable-next-line no-console
      console.log('wait for polyfill');
      waitPolyfillTimeout = setTimeout(waitPolyfill, 50);
    }
  };

  waitPolyfill();
}
