import { List, Record } from 'immutable';
import { Attribute, ClassMetadata } from 'rest-client-sdk';
// eslint-disable-next-line import/no-cycle
import ContractUserClient from '../client/ContractUserClient';
import { PartialEntity } from '.';

export type ContractUserType = {
  '@id': null | string;
  '@type': 'ContractUser';
  userId: null | number;
  contractId: null | string;
  deskSellingDeviceId: null | string;
  position: null | number;
  roles: null | List<string>;
};

class ContractUser extends Record<ContractUserType>({
  '@id': null,
  '@type': 'ContractUser',
  userId: null,
  contractId: null,
  deskSellingDeviceId: null,
  position: null,
  roles: null,
}) {
  public static classMetadata: ClassMetadata;

  constructor(
    val: PartialEntity<ContractUserType> = {
      '@id': null,
      '@type': 'ContractUser',
    }
  ) {
    super(val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/api/contract_user/', '') || '';
  }
}

ContractUser.classMetadata = new ClassMetadata(
  'contractUser',
  'contract_user',
  /** @ts-expect-error -- method signature are incompatible */
  ContractUserClient
);

ContractUser.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('userId'),
  new Attribute('contractId'),
  new Attribute('deskSellingDeviceId'),
  new Attribute('position'),
  new Attribute('roles'),
]);

export default ContractUser;
