import { List, Record } from 'immutable';
import { Attribute, ClassMetadata } from 'rest-client-sdk';
// eslint-disable-next-line import/no-cycle
import UserClient from '../client/UserClient';
import { PartialEntity } from '.';

export type UserType = {
  '@id': null | string;
  '@type': 'User';
  firstname: null | string;
  lastname: null | string;
  fullName: null | string;
  email: null | string;
  enabled: null | boolean;
  avatar: null | string;
  address: null | string;
  roles: null | string[];
  phoneNumber: null | string;
  city: null | string;
  impersonated: null | boolean;
  contractUserList: null | List<Map<string, unknown>>;
  isMapadoTeam: null | boolean;
};

class User extends Record<UserType>({
  '@id': null,
  '@type': 'User',
  firstname: null,
  lastname: null,
  fullName: null,
  enabled: null,
  email: null,
  avatar: null,
  roles: null,
  address: null,
  phoneNumber: null,
  city: null,
  impersonated: null,
  contractUserList: null,
  isMapadoTeam: null,
}) {
  public static classMetadata: ClassMetadata;

  constructor(
    val: PartialEntity<UserType> = {
      '@id': null,
      '@type': 'User',
    }
  ) {
    super(val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/api/users/', '') || '';
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/** @ts-expect-error -- method signature are incompatible */
User.classMetadata = new ClassMetadata('user', 'users', UserClient);
User.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('firstname'),
  new Attribute('lastname'),
  new Attribute('fullName'),
  new Attribute('email'),
  new Attribute('enabled'),
  new Attribute('avatar'),
  new Attribute('address'),
  new Attribute('phoneNumber'),
  new Attribute('city'),
  new Attribute('roles'),
  new Attribute('impersonated', 'impersonated', 'boolean'),
  new Attribute('contractUserList', 'contractUserList', 'array'),
]);

export default User;
