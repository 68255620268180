import { Mapping, MetadataDefinition } from 'rest-client-sdk';
import Collection from './entity/Collection';
// eslint-disable-next-line import/no-cycle
import ContractUser from './entity/ContractUser';
import DeskNotification from './entity/DeskNotification';
// eslint-disable-next-line import/no-cycle
import SupportResource from './entity/SupportResource';
// eslint-disable-next-line import/no-cycle
import User from './entity/User';
// eslint-disable-next-line import/no-cycle
import UserDeskNotification from './entity/UserDeskNotification';

const mapping = new Mapping('/api');

const entityList = [
  UserDeskNotification,
  DeskNotification,
  SupportResource,
  ContractUser,
  User,
];

entityList.forEach((entity) => {
  if (!entity.classMetadata) {
    // eslint-disable-next-line no-console
    console.warn(`No metadata found for entity named "${entity.name}"`);
  }
});

const classMetadataList = entityList.map((entity) => entity.classMetadata);

mapping.setMapping(classMetadataList);

export default mapping;

interface EntityMetadata<T> extends MetadataDefinition {
  entity: T;
  list: Collection<T>;
}

export type TSMetadata = {
  userDeskNotification: EntityMetadata<UserDeskNotification>;
  deskNotification: EntityMetadata<DeskNotification>;
  supportResource: EntityMetadata<SupportResource>;
  contractUser: EntityMetadata<ContractUser>;
  user: EntityMetadata<User>;
};
