import { is, List, Map, MapOf, Record as ImmutableRecord } from 'immutable';
import type { AllowedFactoryTypes } from '../entityFactory/types';

export type CollectionType<E> = {
  '@id': string | null;
  '@type': 'hydra:Collection';
  'hydra:member': List<E>;
  'hydra:totalItems': number;
  'hydra:view': MapOf<ViewType>;
};

export type ViewType = {
  '@id'?: string;
  '@type': 'hydra:PartialCollectionView';
  'hydra:first'?: string;
  'hydra:last'?: string;
  'hydra:next'?: string;
  'hydra:previous'?: string;
};

type ViewTypePageProp =
  | 'hydra:first'
  | 'hydra:last'
  | 'hydra:next'
  | 'hydra:previous';

export type CollectionInputType<E> = Partial<
  Pick<CollectionType<E>, '@id' | '@type' | 'hydra:totalItems'> & {
    'hydra:member': List<AllowedFactoryTypes> | Array<AllowedFactoryTypes>;
    'hydra:view': MapOf<ViewType>;
  }
>;

const defaultValues: CollectionType<unknown> = {
  '@id': null,
  '@type': 'hydra:Collection',
  'hydra:totalItems': 0,
  'hydra:member': List(),
  'hydra:view': Map<ViewType>({
    '@type': 'hydra:PartialCollectionView',
  }),
};

const CollectionFactory = ImmutableRecord<CollectionType<unknown>>(
  defaultValues
);

class Collection<E> extends CollectionFactory implements Iterable<E> {
  private index: number;

  constructor(val: Partial<CollectionType<E>>) {
    const out = super(val);

    this.index = 0;

    // TODO remove this useless return ? (See https://www.bennadel.com/blog/2522-providing-a-return-value-in-a-javascript-constructor.htm)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /** @ts-expect-error */
    return out;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /** @ts-expect-error -- possible conflict with immutable */
  [Symbol.iterator](): Iterator<E> {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      next: (...args) => {
        if (this.index < this.getMembers().size) {
          return {
            // eslint-disable-next-line no-plusplus
            value: this.getMembers().get(this.index++) as E,
            done: false,
          };
        }

        this.index = 0; // If we would like to iterate over this again without forcing manual update of the index

        return { done: true, value: undefined };
      },
    };
  }

  getPage(type: ViewTypePageProp): string | null {
    return this['hydra:view'].get(type) || null;
  }

  getFirstPage(): string | null {
    const first = this.getIn(['hydra:view', 'hydra:first']) as
      | ViewTypePageProp
      | undefined;

    if (!first) {
      return null;
    }

    return this.getPage(first);
  }

  getNextPage(): string | null {
    const next = this.getIn(['hydra:view', 'hydra:next']) as
      | ViewTypePageProp
      | undefined;

    if (!next) {
      return null;
    }

    return this.getPage(next);
  }

  getLastPage(): string | null {
    const last = this.getIn(['hydra:view', 'hydra:last']) as
      | ViewTypePageProp
      | undefined;

    if (!last) {
      return null;
    }

    return this.getPage(last);
  }

  getPreviousPage(): string | null {
    const previous = this.getIn(['hydra:view', 'hydra:previous']) as
      | ViewTypePageProp
      | undefined;

    if (!previous) {
      return null;
    }

    return this.getPage(previous);
  }

  getMembers(): List<E> {
    return this['hydra:member'] as List<E>;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /** @ts-expect-error */
  merge(newCollection: Collection<E>): Collection<E> {
    const newMembers = this.getMembers()
      // concat the two lists
      .concat(newCollection.getMembers())
      // group by id to reduce duplicates
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /** @ts-expect-error -- the @id is defined for all our entities */
      .groupBy((m) => m.get('@id'))
      // reduce duplicate using `merge` function or the Record
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /** @ts-expect-error -- hard to work on that, working for now */
      .map((memberList) => memberList.reduce((prev, curr) => prev.merge(curr)));

    const newMembersSize = newMembers.size as number;
    const updatedCollection = this.set(
      'hydra:member',
      newMembers.valueSeq().toList()
    ).set('hydra:totalItems', newMembersSize);

    if (
      newMembersSize !== this.get('hydra:totalItems') ||
      !is(updatedCollection, this)
    ) {
      return updatedCollection;
    }

    return this;
  }
}

export default Collection;
