export const SUPPORTED_CURRENCY_LIST = [
  'EUR',
  'CHF',
  'GBP',
  'USD',
  'CAD',
  'XPF',
  'MAD',
];

export const FRENCH_COUNTRIES = ['FR', 'CORSE', 'MQ', 'RE', 'GP', 'GF'];

export const MAP_TOKEN =
  'pk.eyJ1IjoibWFwYWRvIiwiYSI6ImNra3k1a2UwNzF4bXoyb3BsY2Jncm1uZWsifQ.ee2nB5QO7Klm8sDSkIV1iw';

export const MAPADO_API_CLIENT_ID =
  '233_4o3ymtclkcys8gk4w04g4gooscskgsg0c4ws0ooooco884048c';

export const SCOPES = [
  'ticketing:accountability:read',
  'activity:all:read',
  'ticketing:carts:read',
  'ticketing:carts:write',
  'ticketing:contracts:read',
  'ticketing:contracts:write',
  'ticketing:customers:write',
  'ticketing:customer_statuses:read',
  'ticketing:customer_statuses:write',
  'ticketing:desk_payment_methods:read',
  'ticketing:desk_payment_methods:write',
  'ticketing:desk_sessions:read',
  'ticketing:desk_sessions:write',
  'ticketing:device_states:read',
  'ticketing:device_states:write',
  'ticketing:events:read',
  'ticketing:events:write',
  'ticketing:invoices:read',
  'ticketing:master_tickets:write',
  'ticketing:modules:read',
  'ticketing:modules:write',
  'ticketing:minisites:read',
  'ticketing:minisites:write',
  'ticketing:notifications:read',
  'ticketing:invoices:write',
  'ticketing:offer_rules:read',
  'ticketing:offer_rules:write',
  'ticketing:order_items:read',
  'ticketing:orders:read',
  'ticketing:orders:write',
  'ticketing:payment_fees:read',
  'ticketing:payouts:read',
  'ticketing:refunds:read',
  'ticketing:refunds:write',
  'ticketing:scans:write',
  'ticketing:taxes:read',
  'ticketing:seat_configs:read',
  'ticketing:logical_seat_configs:read',
  'ticketing:logical_seat_configs:write',
  'ticketing:seat_reserveds:read',
  'ticketing:seat_reserveds:write',
  'ticketing:seat_groups:read',
  'ticketing:seat_groups:write',
  'ticketing:selling_devices:read',
  'ticketing:ticket_price_groups:read',
  'ticketing:ticket_price_groups:write',
  'ticketing:tickets:read',
  'ticketing:tickets:write',
  'ticketing:users:read',
  'ticketing:users:write',
  'crm:customers:read',
  'crm:customers:write',
  'ticketing:features:read',
  'ticketing:seats:read',
  'ticketing:available_seats:read',
  'ticketing:available_seats:write',
  'ticketing:available_seat_models:read',
  'ticketing:available_seat_models:write',
  'ticketing:stock_contingents:read',
  'ticketing:stock_contingents:write',
  'ticketing:contingents:read',
  'ticketing:contingents:write',
  'ticketing:wallets:write',
  'ticketing:wallets:read',
  'ticketing:clients:read',
  'ticketing:clients:write',
  'ticketing:transactions:read',
  'ticketing:ticketings:write',
  'ticketing:venues:read',
  'ticketing:venues:write',
  'ticketing:sibil:read',
  'ticketing:sibil:write',
  'ticketing:coupons:read',
  'ticketing:coupons:write',
  'ticketing:deposits:read',
  'ticketing:deposits:write',
  'ticketing:letterings:read',
  'ticketing:letterings:write',
  'ticketing:transaction_groups:read',
  'ticketing:customer_fields:read',
  'ticketing:customer_field_contracts:read',
  'ticketing:customer_field_contracts:write',
  'ticketing:provider_ticket_credentials:write',
  'ticketing:tags:read',
  'ticketing:tags:write',
  'ticketing:seasons:read',
  'ticketing:seasons:write',
  'ticketing:ticketing_categories:read',
  'ticketing:ticketing_categories:write',
  'ticketing:jobs:read',
  'ticketing:jobs:write',
  'ticketing:organizational_units:read',
  'ticketing:organizational_units:write',
  'ticketing:stickers:read',
  'ticketing:stickers:write',
  'ticketing:subscriptions:read',
  'ticketing:subscriptions:write',
  'ticketing:subscription_maturity:read',
  'ticketing:subscription_maturity:write',
  'ticketing:provider_ticket_imported_files:read',
  'ticketing:provider_ticket_imported_files:write',
  'ticketing:booking_tracks:read',
  'ticketing:booking_tracks:write',
  'ticketing:booking_track_modes:read',
  'ticketing:scan_tracks:read',
  'ticketing:scan_tracks:write',
  'ticketing:checkpoints:read',
  'ticketing:checkpoints:write',
  'ticketing:ticket_prices:read',
  'ticketing:ticket_prices:write',
].join(' ');

export const SENTRY_DSN =
  'https://8c11fa00e15141828bc9539378aac3cf@sentry.mp.waays.eu/4';

export const NOT_ON_SALE_BOOKABLE_STOCK = 'bookableStock';
export const NOT_ON_SALE_START_DATE = 'startDate';
export const NOT_ON_SALE_SALE_START_DATE = 'saleStartDate';
export const NOT_ON_SALE_SALE_END_DATE = 'saleEndDate';
export const NOT_ON_SALE_EVENT_DATE = 'eventDate';
export const NOT_ON_SALE_STATUS = 'status';
export const NOT_ON_SALE_CONTRACT = 'contract';

export const BYPASS_NOT_ON_SALE_REASONS = [
  NOT_ON_SALE_BOOKABLE_STOCK,
  NOT_ON_SALE_START_DATE,
  NOT_ON_SALE_SALE_START_DATE,
  NOT_ON_SALE_SALE_END_DATE,
  NOT_ON_SALE_EVENT_DATE,
];

export const FETCH_STATUS_NOT_STARTED = 'NOT_STARTED';
export const FETCH_STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const FETCH_STATUS_SUCCEEDED = 'SUCCEEDED';
export const FETCH_STATUS_FAILED = 'FAILED';

export const OFFER_CURRENT_SCHEMA_VERSION = 2;

const STRIPE_PROD_CLIENT_ID = 'ca_7HG0LT7YIkOUR1WpTzb0AQs9QUZdBRhi';
const STRIPE_DEV_CLIENT_ID = 'ca_7HG0H1eFICxvNEuOsnWeB7YScVllkeCi';

let deskDomain = 'desk.mapado.com';

if (process.env.NODE_ENV !== 'production') {
  deskDomain = 'desk.preprod.mapado.com';

  if (process.env.VADER_USER) {
    deskDomain = `desk.${process.env.VADER_USER}.dev.mapado.com`;
  }
}

export const STRIPE_OAUTH_URL = `https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write&client_id=${
  process.env.NODE_ENV === 'production'
    ? STRIPE_PROD_CLIENT_ID
    : STRIPE_DEV_CLIENT_ID
}&redirect_uri=https://${deskDomain}/onboarding`;

export const HERE_API_KEY = '4RbyexiG50ESaPpRZ9OtwMrtOwQdrtebZ1NyPsUpk0Y';

export const OPENREPLAY_PROJECT_KEY = 'GvG4TtTGojTo4nwdmzkM';
