import { AbstractClient } from 'rest-client-sdk';
import { USER_TYPE } from 'mapado-ticketing-js-sdk';
import Collection from '../entity/Collection';
// eslint-disable-next-line import/no-cycle
import UserDeskNotification from '../entity/UserDeskNotification';
// eslint-disable-next-line import/no-cycle
import { TSMetadata } from '../mapping';

class UserDeskNotificationClient extends AbstractClient<
  TSMetadata['userDeskNotification']
> {
  findNotDiscarded(): Promise<Collection<UserDeskNotification>> {
    const params: Record<string, unknown> = {
      'deskNotification.startsAt[before]': 'now',
      'deskNotification.expiresAt[after]': 'now',
      'order[deskNotification.createdAt]': 'desc',
      'exists[discardedAt]': false,
      user: USER_TYPE.ME,
      itemsPerPage: 10,
    };

    return this.findBy(params);
  }
}

export default UserDeskNotificationClient;
