import { SCOPES } from '../constants';
import type { DeskConfig } from '../types';

export const ACCOUNTS_DOMAIN = 'accounts.mapado.com';

const Config: DeskConfig = {
  oauth: {
    path: `desk.mapado.com/api/oauth/proxy`,
    scheme: 'https',
    scope: SCOPES,
  },

  ticketing: {
    path: 'ticketing.mapado.net',
    scheme: 'https',
    unitOfWorkEnabled: true,
  },

  crm: {
    path: 'crm.mapado.net',
    scheme: 'https',
  },

  account: {
    path: 'accounts.mapado.com',
    scheme: 'https',
    unitOfWorkEnabled: true,
  },

  socket: {
    scheme: 'https',
    host: 'socket.mapado.net',
    port: '443',
  },
};

export default Config;
