import { Record } from 'immutable';
import { Attribute, ClassMetadata } from 'rest-client-sdk';
import { PartialEntity } from '.';

export enum RESOURCE_DOMAIN {
  DESK = 'desk',
  CRM = 'crm',
  TICKET_IMPORT = 'provider_ticket_import',
  OFFER = 'offer',
  BOOKING_TRACK = 'booking_track',
}

export type SupportResourceType = {
  '@id': null | string;
  '@type': 'SupportResource';
  type: null | string;
  title: null | string;
  image: null | string;
  text: null | string;
  link: null | string;
  position: null | number;
  enabled: null | boolean;
  domain: null | RESOURCE_DOMAIN;
};

class SupportResource extends Record<SupportResourceType>({
  '@id': null,
  '@type': 'SupportResource',
  type: null,
  title: null,
  image: null,
  text: null,
  link: null,
  position: null,
  enabled: null,
  domain: null,
}) {
  public static classMetadata: ClassMetadata;

  constructor(
    val: PartialEntity<SupportResourceType> = {
      '@id': null,
      '@type': 'SupportResource',
    }
  ) {
    super(val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/api/support_resources/', '') || '';
  }
}

SupportResource.classMetadata = new ClassMetadata(
  'supportResource',
  'support_resources'
);
SupportResource.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('type'),
  new Attribute('title'),
  new Attribute('image'),
  new Attribute('text'),
  new Attribute('link'),
  new Attribute('position'),
  new Attribute('enabled'),
  new Attribute('domain'),
]);

export default SupportResource;
