import { Record } from 'immutable';
import { Attribute, ClassMetadata, AbstractClient } from 'rest-client-sdk';
import { PartialEntity } from '.';

export type DeskNotificationType = {
  '@id': null | string;
  '@type': 'DeskNotification';
  type: null | string;
  message: null | string;
};

class DeskNotification extends Record<DeskNotificationType>({
  '@id': null,
  '@type': 'DeskNotification',
  type: null,
  message: null,
}) {
  public static classMetadata: ClassMetadata;

  constructor(
    val: PartialEntity<DeskNotificationType> = {
      '@id': null,
      '@type': 'DeskNotification',
    }
  ) {
    super(val);
  }

  getShortId(): string {
    return this.get('@id')?.replace('/desk_notifications/', '') || '';
  }
}

DeskNotification.classMetadata = new ClassMetadata(
  'deskNotification',
  'desk_notifications',
  AbstractClient
);
DeskNotification.classMetadata.setAttributeList([
  new Attribute('@id', '@id', 'string', true),
  new Attribute('@type'),
  new Attribute('message'),
  new Attribute('type'),
]);

export default DeskNotification;
