import { compile, PathFunction } from 'path-to-regexp';
import * as routes from './routes';

type ValueOf<T> = T[keyof T];
export type AcceptedRoutes = ValueOf<typeof routes>;

const generateRouteCache: Partial<Record<AcceptedRoutes, PathFunction>> = {};

type RouteParams = Record<string, number | string>;

type ExtractParam<Path, NextPart> = Path extends `:${infer Param}`
  ? Record<Param, string | number> & NextPart
  : NextPart;

type ExtractParams<Path> = Path extends `${infer Segment}/${infer Rest}`
  ? ExtractParam<Segment, ExtractParams<Rest>>
  : // eslint-disable-next-line @typescript-eslint/ban-types
    ExtractParam<Path, {}>;

type HasParams<Route> = Route extends `${infer BeforeParam}:${infer AfterParam}`
  ? Route
  : never;
type DontHaveParams<
  Route
> = Route extends `${infer BeforeParam}:${infer AfterParam}` ? never : Route;

interface RouteInterface {
  <R extends HasParams<AcceptedRoutes>>(
    type: R,
    params: ExtractParams<R>
  ): string;
  <R extends DontHaveParams<AcceptedRoutes>>(type: R): string;
}

export const route: RouteInterface = (
  type: AcceptedRoutes,
  params?: RouteParams
) => {
  if (!generateRouteCache[type]) {
    generateRouteCache[type] = compile(type);
  }

  // @ts-expect-error -- value has been set in the `if`. If we make a temporary variable the issue disapear
  return generateRouteCache[type](params);
};
